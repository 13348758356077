/* Base */
body {
  line-height: 1.7;
  color: #111111;
  font-weight: 500;
  font-size: 1rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

::selection {
  background: #000;
  color: #fff;
}

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.hover-underline {
  text-decoration: none;
  color: #2cae44;
}

.hover-underline:hover {
  text-decoration: underline;
  color: #2cae44;
}

.external-link {
  color: #111111;
  font-weight: 600;
}

.external-link:hover {
  color: #111111;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.bg-light {
  background: #f6f6f6 !important;
}

.text-black {
  color: #000 !important;
}

.site-wrap:before {
  display: none;
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

.offcanvas-menu .site-wrap {
  height: 100%;
  width: 100%;
  z-index: 2;
}

.offcanvas-menu .site-wrap:before {
  opacity: 1;
  visibility: visible;
}

.btn {
  font-size: 16px;
  border-radius: 30px;
  padding: 10px 30px;
}

.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.btn-primary {
  border-width: 2px;
  background: #2cae44;
  border-color: #fff;
  color: #fff;
  -webkit-box-shadow: 0 4px 20px -5px rgba(0, 210, 181, 0.4);
  box-shadow: 0 4px 20px -5px rgba(0, 210, 181, 0.4);
}

.btn.btn-primary:hover {
  background: #fff;
  border-color: #2cae44;
  color: #000;
}

.btn.btn-navbar {
  border-width: 1px;
  border-radius: 1px;
  background: transparent;
  border-color: #2cae44;
  color: #fff;
}

.btn.btn-navbar:hover {
  background: solid;
  border-color: #2cae44;
  color: #fff;
}

.form-control {
  height: 43px;
  border-radius: 10px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.form-control:active,
.form-control:focus {
  border-color: #2cae44;
}

.form-control:hover,
.form-control:active,
.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-control#inputMessage {
  height: 200px;
}

.site-section {
  padding: 2em 0 0 0;
}

/* @media (min-width: 768px) {
  .site-section {
    padding: 5em 0 0 0;
  }
} */

.border-top {
  border-top: 1px solid #edf0f5 !important;
}

.ul-check {
  margin-bottom: 50px;
}

.ul-check li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  line-height: 1.5;
}

.ul-check li:before {
  left: 0;
  font-size: 20px;
  top: -.3rem;
  font-family: "icomoon";
  content: "\e5ca";
  position: absolute;
}

.ul-check.white li:before {
  color: #fff;
}

.ul-check.success li:before {
  color: #8bc34a;
}

.ul-check.primary li:before {
  color: #2cae44;
}

/* Navbar */
.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  width: 100%;
  background: #fff;
  text-align: center;
}

.site-navbar .site-logo {
  position: relative;
  left: 0;
  top: -5px;
}

.site-navbar .site-navigation.border-bottom {
  border-bottom: 1px solid #f3f3f4 !important;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu .active {
  color: #2cae44;
  display: inline-block;
  padding: 5px 20px;
}

.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}

.site-navbar .site-navigation .site-menu>li {
  display: inline-block;
}

.site-navbar .site-navigation .site-menu>li>a {
  padding: 5px 20px;
  color: #000;
  display: inline-block;
  text-decoration: none !important;
}

.site-navbar .site-navigation .site-menu>li>a:hover {
  color: #2cae44;
}

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children>a {
  position: relative;
  padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children>a:before {
  position: absolute;
  content: "\e313";
  font-size: 16px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'icomoon';
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  /* display: none; */
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  border-top: 2px solid #2cae44;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #edf0f5;
  border-right: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active>a {
  color: #2cae44 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li>a {
  padding: 9px 20px;
  display: block;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li>a:hover {
  background: #f4f5f9;
  color: #25262a;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>a:before {
  content: "\e315";
  right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>.dropdown,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children>ul {
  left: 100%;
  top: 0;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:hover>a,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:active>a,
.site-navbar .site-navigation .site-menu .has-children .dropdown>li.has-children:focus>a {
  background: #f4f5f9;
  color: #25262a;
}

.site-navbar .site-navigation .site-menu .has-children:hover>a,
.site-navbar .site-navigation .site-menu .has-children:focus>a,
.site-navbar .site-navigation .site-menu .has-children:active>a {
  color: #2cae44;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover>.dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus>.dropdown,
.site-navbar .site-navigation .site-menu .has-children:active>.dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
}

.offcanvas-menu .site-mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #25262a;
}

.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #212529;
}

.site-mobile-menu .site-nav-wrap a:hover {
  color: #2cae44;
}

.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}

.site-mobile-menu .site-nav-wrap li .active {
  color: #2cae44;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: "icomoon";
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap>li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.site-mobile-menu .site-nav-wrap>li>a {
  padding-left: 20px;
  font-size: 20px;
}

.site-mobile-menu .site-nav-wrap>li>ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-mobile-menu .site-nav-wrap>li>ul>li {
  display: block;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>a {
  padding-left: 40px;
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul {
  padding: 0;
  margin: 0;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li {
  display: block;
}

.site-mobile-menu .site-nav-wrap>li>ul>li>ul>li>a {
  font-size: 16px;
  padding-left: 60px;
}

.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.sticky-wrapper .site-navbar {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.sticky-wrapper.is-sticky .site-navbar {
  -webkit-box-shadow: 4px 0 20px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 0 20px -10px rgba(0, 0, 0, 0.2);
}

.sticky-wrapper .shrink {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* Blocks */

.site-blocks-cover {
  background-size: cover;
  background-size: 100% 200%;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}

.site-blocks-cover:after {
  position: absolute;
  /* background-image: url("../images/svg_1.svg"); */
  content: "";
  right: 0;
}

.site-blocks-cover.overlay {
  position: relative;
}

.site-blocks-cover.overlay:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
}

.site-blocks-cover .player {
  position: absolute;
  bottom: -250px;
  width: 100%;
}

.site-blocks-cover,
.site-blocks-cover>.container>.row {
  /* min-height: 600px; */
  /* height: calc(100vh); */
}

.site-blocks-cover h1 {
  font-size: 2.5rem;
  font-weight: 400;
  color: #000;
  font-weight: 900;
}

@media (max-width: 991.98px) {
  .site-blocks-cover h1 {
    font-size: 2rem;
  }
}

.site-blocks-cover p {
  color: #111111;
  font-size: 1rem;
  font-weight: 500;
}

.img-absolute {
  position: absolute;
  bottom: 0;
  right: 20%;
  top: 50%;
  max-width: 800px;
  -webkit-transform: translateY(-50%) translateX(30%);
  -ms-transform: translateY(-50%) translateX(30%);
  transform: translateY(-50%) translateX(30%);
}

.img-absolute img {
  max-width: 100%;
}

.img-fluid {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 80%; */
  /* max-height: 500px; */
  margin-bottom: 50px;
}

.img-fluid-score {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 50px;
}

@media (max-width: 900px) {
  .img-absolute {
    position: relative;
    top: auto;
    -webkit-transform: translateY(0%) translateX(0%);
    -ms-transform: translateY(0%) translateX(0%);
    transform: translateY(0%) translateX(0%);
    margin-bottom: 30px;
  }
}

.bg-light {
  background: #ccc;
}

.slide-one-item {
  position: relative;
  z-index: 1;
}

.slide-one-item .owl-nav {
  position: relative;
  position: absolute;
  bottom: -90px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.slide-one-item .owl-nav .owl-prev,
.slide-one-item .owl-nav .owl-next {
  position: relative;
  display: inline-block;
  padding: 20px;
  font-size: 30px;
  color: #000;
}

.slide-one-item .owl-nav .owl-prev.disabled,
.slide-one-item .owl-nav .owl-next.disabled {
  opacity: .2;
}

.slide-one-item.home-slider .owl-nav {
  position: absolute !important;
  top: 50% !important;
  bottom: auto !important;
  width: 100%;
}

@media (max-width: 991.98px) {
  .slide-one-item.home-slider .owl-nav {
    display: none;
  }
}

.slide-one-item.home-slider .owl-prev {
  left: 10px !important;
}

.slide-one-item.home-slider .owl-next {
  right: 10px !important;
}

.slide-one-item.home-slider .owl-prev,
.slide-one-item.home-slider .owl-next {
  color: #fff;
  position: absolute !important;
  top: 50%;
  padding: 0px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  line-height: 0;
  text-align: center;
  font-size: 25px;
}

@media (min-width: 768px) {

  .slide-one-item.home-slider .owl-prev,
  .slide-one-item.home-slider .owl-next {
    font-size: 25px;
  }
}

.slide-one-item.home-slider .owl-prev>span,
.slide-one-item.home-slider .owl-next>span {
  position: absolute;
  line-height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slide-one-item.home-slider .owl-prev:hover,
.slide-one-item.home-slider .owl-prev:focus,
.slide-one-item.home-slider .owl-next:hover,
.slide-one-item.home-slider .owl-next:focus {
  background: black;
}

.slide-one-item.home-slider:hover .owl-nav,
.slide-one-item.home-slider:focus .owl-nav,
.slide-one-item.home-slider:active .owl-nav {
  opacity: 10;
  visibility: visible;
}

.slide-one-item .owl-dots {
  text-align: center;
}

.slide-one-item .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}

.slide-one-item .owl-dots .owl-dot span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #dee2e6;
}

.slide-one-item .owl-dots .owl-dot.active span {
  background: #2cae44;
}

#date-countdown .countdown-block {
  color: #b3b3b3;
}

#date-countdown .label {
  font-size: 40px;
  color: #000;
}

.player {
  position: relative;
}

.player img {
  max-width: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.player h2 {
  font-size: 20px;
  letter-spacing: .2em;
  text-transform: uppercase;
}

.player .position {
  font-size: 14px;
  color: #b3b3b3;
  text-transform: uppercase;
}

.unit-4 {
  padding: 30px;
  background: #fff;
  border-radius: 5px;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  position: relative;
  top: 0;
}

.solution-box-height {
  min-height: 250px;
  margin-bottom: 50px;
  padding-bottom: 20px;
}

.pricing-box-height {
  min-height: 380px;
}

.unit-4 .unit-4-icon {
  display: flex;
  align-items: center; /* This will vertically align the icon with the text */
  gap: 10px; /* This adds space between the icon and the title */
}

.icon-wrap {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #2cae44;
  -webkit-box-shadow: 0 4px 20px -5px rgba(0, 210, 181, 0.4);
  box-shadow: 0 4px 20px -5px rgba(0, 210, 181, 0.4);
}

.icon-wrap span {
  font-size: 1.5rem;
  color: #fff !important;
}

.unit-4 h2 {
  line-height: 40px;
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.unit-4 p {
  font-weight: 500;
  font-size: 17px;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

/* .unit-4 *:last-child {
  margin-bottom: 0;
} */

/* .unit-4:hover {
  top: -7px;
}

.unit-4:hover .icon-wrap {
  background-color: #fff;
}

.unit-4:hover .icon-wrap span {
  color: #2cae44 !important;
}

.unit-4:hover h3 {
  color: #fff;
}

.unit-4:hover p {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.unit-4:hover p a {
  color: #fff;
} */

.h-entry img {
  margin-bottom: 30px;
}

.h-entry .meta {
  color: #b3b3b3;
  font-size: 14px;
}

.h-entry h2 {
  font-size: 20px;
}

.testimonial {
  max-width: 800px;
  margin: 0 auto !important;
  text-align: center;
}

.testimonial blockquote {
  font-size: 1.5rem;
  font-style: italic;
}

.testimonial figure img {
  max-width: 100px;
  margin: 0 auto;
  border-radius: 50%;
}

.person h3 {
  font-size: 18px;
}

.testimonial-wrap .owl-nav {
  display: none;
}

.section-title {
  color: #000;
  font-size: 40px;
  font-weight: 900;
}

@media (max-width: 991.98px) {
  .section-title {
    font-size: 30px;
  }
}

.position-relative {
  position: relative;
}

.feature-big h2 {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 30px;
}

.author-box {
  -webkit-box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #eee;
}

.author-box *:last-child {
  margin-bottom: 0;
}

.author-box img {
  max-width: 50px;
}

.author-box blockquote {
  font-style: italic;
}

.shadow {
  -webkit-box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
}

.h-entry h2 a {
  color: #000;
  font-size: 30px;
}

@media (max-width: 991.98px) {
  .site-logo {
    top: 0;
  }
}

@media (max-width: 991.98px) {
  .main-menu {
    display: none !important;
  }
}

.content {
  margin-top: 5%;
}

.content .h1 {
  font-size: 3.5rem;
  font-weight: 400;
  color: #000;
  font-weight: 900;
}

.img-content {
  position: absolute;
  bottom: 0;
  right: 20%;
  top: 55%;
  max-width: 500px;
  max-height: 500px;
  padding-left: 10%;
  -webkit-transform: translateY(-50%) translateX(30%);
  -ms-transform: translateY(-50%) translateX(30%);
  transform: translateY(-50%) translateX(30%);
}

.img-content img {
  max-width: 100%;
}

@media (max-width: 900px) {
  .img-content {
    position: relative;
    top: auto;
    -webkit-transform: translateY(0%) translateX(0%);
    -ms-transform: translateY(0%) translateX(0%);
    transform: translateY(0%) translateX(0%);
    margin-bottom: 30px;
  }
}

.footer {
  margin-top: 5px;
}


/* ###########################  Toggle Button  ########################################## */

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
  height: 40px;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 140px;
  height: 40px;
  background-color: #2cae44;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 22px;
  font-weight: 600;
  top: 8px;
  right: 20px;
  color: #222;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 70px;
  height: 36px;
  background: #1C2D38;
  border-radius: 8px;
  position: absolute;
  font-size: 22px;
  display: flex;
  justify-content: center;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 5px 0px;
  color: #fff;
  font-weight: 600;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
  background-color: #2cae44;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 68px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}
